import React from 'react';
import "../assets/css/style.css";
import OdooLogo from '../assets/images/odoo/odoo.svg'
import realTimeInsights from '../assets/images/odoo/real-time-insights.png'
import Implementation from '../assets/images/odoo/implementation.png'
import CustomisationDevelopment from '../assets/images/odoo/Customisation -development.png'
import TrainingSupport from '../assets/images/odoo/Training-Support.png'
import Integration from '../assets/images/odoo/Integration.png'
import CertifiedExpertise from '../assets/images/odoo/certified-expertise.png'
import CustomisedSolutions from '../assets/images/odoo/customised-solutions.png'
import TimelyDelivery from '../assets/images/odoo/timely-delivery.png'
import EndToEndSupport from '../assets/images/odoo/end-to-end-support.png'
import { Helmet } from 'react-helmet';

const Odoo = () => {
    return (
        <>
            <Helmet>
                <title>Best Odoo Services | Odoo Development & Implementation | Odoo ERP Solutions</title>
                <meta name="Description" content="Looking for the best Odoo/OpenERP services? Leading Odoo/OpenERP development company. Get expert Odoo services for your ERP projects. Contact us today! " />
                <link rel="canonical" href="https://www.links4engg.com/odoo-service-provider" />
                <meta name="google-site-verification" content="sIAF30LkwI2el_QAyhMMfCqTI_zMkkTTmnponq3za_U" />
            </Helmet>
            <section id="odooSection">
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-12'>
                            <section id="odooBannerImgSection">
                                <div className='container-fluid p-0'>
                                    <div className='row'>
                                        <div className='col-12 p-0 '>
                                            <div className='bg-img'>
                                                <h1>Odoo Solutions For Your Business</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id='odooPartnershipSection'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 my-5'>
                                            <div className='d-flex justify-content-center gap-2 mb-2'>
                                                <h1>Partnership With</h1>
                                                <img src={OdooLogo} />
                                            </div>
                                            <p>At LINKS4ENGG India Private Limited, we are proud to be an official Odoo Partner, empowering businesses with the tools they need to thrive in today’s competitive landscape. Odoo is an all-in-one, customisable suite of business applications that streamline operations, enhance efficiency, and foster growth across industries.</p>
                                        </div>
                                        <div className='col-12'>
                                            <h1>What is Odoo</h1>
                                            <p>Odoo is a comprehensive ERP system designed for businesses of all sizes. With an intuitive interface and modular structure, Odoo simplifies your operations while integrating seamlessly with existing processes. Whether you’re managing sales, finances, inventory, HR, or marketing, Odoo offers a unified platform tailored to meet your specific needs.</p>
                                        </div>
                                        <div className='col-12 my-5'>
                                            <div className='row gx-1 odoo-partnership-row'>
                                                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-12 pr-xxl-1 pr-xl-1 pr-lg-1 pr-md-1 pr-sm-1 mb-1'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <h4><span>01 </span>Modular Structure</h4>
                                                            <ul>
                                                                <li>Over 30 core modules, including CRM, Sales, Inventory, Accounting, and HRMS. </li>
                                                                <li>Add or remove modules based on your requirements.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 pl-xxl-0 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-0 mb-1'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <h4><span>02 </span>User-Friendly Interface</h4>
                                                            <ul>
                                                                <li>Easy to navigate and learn.</li>
                                                                <li>Role-based access for enhanced security and collaboration.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 pr-xxl-1 pr-xl-1 pr-lg-1 pr-md-1 pr-sm-1 mb-1'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <h4><span>03 </span>Seamless Integration</h4>
                                                            <ul>
                                                                <li>Connect with third-party apps, APIs, and tools. </li>
                                                                <li>Works across industries such as manufacturing, retail, and service- based businesses.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-12 pl-xxl-0 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-0 mb-1'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <h4><span>04 </span>Real-Time Insights</h4>
                                                            <ul>
                                                                <li>Advanced reporting and analytics for informed decision-making.  </li>
                                                                <li>Track KPIs a cross departments with interactive dashboards.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-1'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <h4><span>05 </span>Customization</h4>
                                                            <ul>
                                                                <li>Fully customisable modules to fit your unique business processes.  </li>
                                                                <li>Scalable for businesses of all sizes.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="odooServicesSection">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 my-5'>
                                            <h1>Our Odoo Services</h1>
                                            <p>At LINKS4ENGG, we ensure that your Odoo implementation journey is smooth, efficient, and results driven.</p>
                                        </div>
                                    </div>
                                    <div className='row pb-5 justify-content-center Odoo-services-row'>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <img src={realTimeInsights} />
                                                    <h4>Real-Time Insights</h4>
                                                    <p>Identify the right Odoo modules for your business and Provide insights on leveraging Odoo for maximum ROI.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <img src={Implementation} />
                                                    <h4>Implementation</h4>
                                                    <p>End-to-end implementation services tailored to your workflows and Ensure a seamless transition with minimal disruption.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <img src={CustomisationDevelopment} />
                                                    <h4>Customisation & Development</h4>
                                                    <p>Tailor Odoo modules to your unique requirements and Develop additional functionalities to enhance performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <img src={TrainingSupport} />
                                                    <h4>Training & Support</h4>
                                                    <p>Empower your team with in-depth Odoo training and Ongoing support to address technical issues and queries.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <img src={Integration} />
                                                    <h4>Integration & MigrationTraining & Support</h4>
                                                    <p>Connect Odoo to existing software and tools and Migrate data securely from legacy systems to Odoo.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="odooPartnerHighlightsection">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 my-5'>
                                            <h2>Why Choose LINKS4ENGG As Your Odoo Partner?</h2>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center pt-5 pb-4'>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 c0l-sm-12'>
                                            <div className='d-flex gap-3'>
                                                <div><img src={CertifiedExpertise} /></div>
                                                <div>
                                                    <h4>Certified Expertise</h4>
                                                    <p>As an official Odoo Partner, we bring deep technical knowledge and experience.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 c0l-sm-12'>
                                            <div className='d-flex gap-3'>
                                                <div><img src={CustomisedSolutions} /></div>
                                                <div>
                                                    <h4>Customised Solutions</h4>
                                                    <p>Tailored to your industry and business goals.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center pb-5'>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 c0l-sm-12'>
                                            <div className='d-flex gap-3'>
                                                <div><img src={TimelyDelivery} /></div>
                                                <div>
                                                    <h4>Timely Delivery</h4>
                                                    <p>Projects delivered within deadlines without compromising quality.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 c0l-sm-12'>
                                            <div className='d-flex gap-3'>
                                                <div> <img src={EndToEndSupport} /></div>
                                                <div>
                                                    <h4>End-To-End Support:</h4>
                                                    <p>From consultation to post-implementation, we are with you at every step.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section id="odooBusinessSection">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 my-5 '>
                                            <h2 className='mb-3'>Transform Your Business With Odoo</h2>
                                            <p className='mb-3'>Odoo is more than just software; it’s a growth partner. With LINKS4ENGG as your trusted Odoo Partner, unlock the potential of your business through intelligent automation and seamless integration.</p>
                                            <p> Contact us today to learn how Odoo can revolutionise your business!</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );


}
export default Odoo
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import HeadOffice from '../assets/images/head-office.png';
import Email from '../assets/images/email.png';
import Phone from '../assets/images/phone.png';
import Uae from '../assets/images/uae.png';
import Usa from '../assets/images/usa.png';
import Qatar from '../assets/images/qatar.png';
import India from '../assets/images/india.png';
import { Helmet } from "react-helmet";

const ContactUs = () => {
    const [value, setValue] = useState();
    return (
        <>
            <Helmet>
                <title>Contact Us | Links4engg</title>
                <meta name="Description" content=" Get in touch with Links4engg. Contact us for inquiries about our software solutions, services, or career opportunities." />
                <link rel="canonical" href="https://www.links4engg.com/contactus" />
                <meta name="google-site-verification" content="sIAF30LkwI2el_QAyhMMfCqTI_zMkkTTmnponq3za_U" />
            </Helmet>
            <div className='contact-us'>
                <div className="site-pages contact-us">
                    <div className="container">
                        <div className="sec-bottom">
                            <p className="top-title">Contact Us</p>
                            <h2 className='sub-title'>We Are Excited About The
                                <br /> Opportunity To Collaborate With You.</h2>
                        </div>
                    </div>
                </div>
                <div id="contactform">
                    <div className="container">
                        <div className="form-section">
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="expert-box">
                                        <h4><img src={HeadOffice} />Head Office</h4>
                                        <div className="expert-phn">
                                            <p>LINKS4ENGG India Private Limited
                                                <br />
                                                98/2525, PR Complex, Pullukadu Road,
                                                <br />
                                                Kulathoor PO Trivandrum, Kerala,
                                                <br />
                                                India - 695583</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={3}>
                                    <div className="expert-box">
                                        <h4><img src={Phone} />For Enquiry</h4>
                                        <div className="expert-phn">
                                            <p>+917306889096,</p>
                                            <p>+91 471 3592209</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={3}>
                                    <div className="expert-box">
                                        <h4><img src={Email} />Email</h4>
                                        <div className="expert-phn">
                                            <p>info@links4engg.com</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="expert-box letus-know">
                                        <h4>Let Us Know How We Can Help</h4>
                                        <div className="expert-phn">
                                            <p>With experience working with clients of various sizes,
                                                <br /> including startups and enterprise brands, we would
                                                <br /> love to discuss your project and explore how we can<br />
                                                provide value.</p>
                                        </div>
                                    </div>

                                </Col>

                                <Col sm={12} md={6}>

                                    <div className="contact-form">
                                        <form>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder="" required />
                                                        <label className="did-floating-label">Name</label>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " required />
                                                        <label className="did-floating-label">Company</label>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <label class="did-floating-label" style={{ top: "-15px", color: "#152238" }}>Phone Number</label>
                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="IN"
                                                            id='MobileNo'
                                                            name='wspConPhone'
                                                            placeholder="Mobile Number"
                                                            value={value}
                                                            onChange={setValue}
                                                        />

                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " name="" />
                                                        <label className="did-floating-label">Email</label>

                                                    </div>
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " />
                                                        <label className="did-floating-label">Message</label>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={7}>
                                                    <div class="alert alert-success alert-dismissable">
                                                        <button type="button" class="close" aria-hidden="true"></button>
                                                        Message sent successfully.
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={5} className="submit-sec">
                                                    <a type="submit" className="btn submit-btn">Send Message</a>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='locations'>
                            <Row>
                                <Col sm={12} md={3} className='px-2'>
                                    <div className='loc-box'>
                                        <img src={Uae} />
                                        <h2>UAE</h2>
                                        <p>LINKS4ENGG FZE</p>
                                        <p>Office no: 123, Business Center: 2</p>
                                        <p>Ras Al Khaimah, UAE</p>
                                        <p>P.O Box: 56097</p>
                                        <p>+971 72046035</p>
                                        <p>info@links4engg.com</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} className='px-2'>
                                    <div className='loc-box'>
                                        <img src={Usa} />
                                        <h2>USA</h2>
                                        <p>LINKS4ENGG</p>
                                        <p>213 Spruce St. Charlottesville</p>
                                        <p>VA 22902</p>
                                        <p>+1 (434) 218-8403</p>
                                        <p>info@links4engg.com</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} className='px-2'>
                                    <div className='loc-box'>
                                        <img src={Qatar} />
                                        <h2>QATAR</h2>
                                        <p>LINKS FOR  ENGG TRADING WLL</p>
                                        <p>P O Box : 7922, Al Asiri, Salwa Road, Doha</p>
                                        <p>+974 4499 5077, info@links4engg.com</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} className='px-2'>
                                    <div className='loc-box'>
                                        <img src={India} />
                                        <h2>INDIA</h2>
                                        <p>LINKS4ENGG INDIA PVT. LTD</p>
                                        <p>98/2525, PR Complex, Pullukadu Road, Kulathoor PO </p>
                                        <p>Trivandrum, Kerala</p>
                                        <p>+91 471 3592209,  +917306889096</p>
                                        <p>info@links4engg.com </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ContactUs;
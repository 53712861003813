import React from "react";
import { Link } from 'react-router-dom'
import "../assets/css/style.css";
import { Container, Row, Col } from 'react-bootstrap';
import Belonging from '../assets/images/belonging.png';
import Passions from '../assets/images/passion.png';
import EqualPay from '../assets/images/equal-pay.png';
import Flexibility from '../assets/images/flexibility-b.png';
import Growth from '../assets/images/growth-b.png';
import Healthcare from '../assets/images/health-care.svg';
import Insurance from '../assets/images/insurance.svg';
import Training from '../assets/images/training.svg';
import { BsFillCaretRightFill, BsFillCaretDownFill } from "react-icons/bs";
import News1 from '../assets/images/news1.png';
import News2 from '../assets/images/news2.png';
import News3 from '../assets/images/news3.png';
import { Helmet } from "react-helmet";

const Careers = () => {
    return (
        <>
            <Helmet>
                <title>Career Opportunities | Links4engg - Grow With Us</title>
                <meta name="Description" content="Advance your career with Links4engg. We're committed to employee growth and offer exciting opportunities for talented professionals. Explore our open roles. " />
                <link rel="canonical" href="https://www.links4engg.com/careers" />
                <meta name="google-site-verification" content="sIAF30LkwI2el_QAyhMMfCqTI_zMkkTTmnponq3za_U" />
            </Helmet>
            <div className="careers">
                <div className="site-pages careers">
                    <div className="container">
                        <div className="sec-bottom">
                            <p className="top-title">Careers</p>
                            <h2 className='sub-title'>Let's Grow Together</h2>
                        </div>
                    </div>
                </div>
                <Col md={12}>
                    <Row>
                        <Col md={12} className="p-0">
                            <div className="about-company">
                                <div className="container">
                                    <p className="sec-top-title">LINKS4ENGG</p>
                                    <div className="sec-bottom">
                                        <h2 className="sec-title">Not Just A Workplace, A Home</h2>

                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <div className="vision-box">
                                                <img src={Belonging} />
                                                <div className="vision-text">
                                                    <h2>Belonging</h2>
                                                    <p>We’ve got your back. Our People Experience Partners meet with you monthly to listen to your concerns, support you, and ensure you know you can evolve and be proud of your work. Your feedback helps us improve as a team. And when your workplace recognises your successes, you’ll never want to leave.</p>
                                                </div>
                                            </div>
                                            <div className="vision-box">
                                                <img src={Passions} />
                                                <div className="vision-text">
                                                    <h2>Passions</h2>
                                                    <p>A values statement lists the core principles that guide and direct the organization and its culture. In a values-led organization, the values create a moral compass for the organization and its employees.</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="right-side">
                                                <div className="vision-box">
                                                    <img src={Flexibility} />
                                                    <div className="vision-text">
                                                        <h2>Flexibility</h2>
                                                        <p>We love what we do and how we help our clients build the future. We are remote-first, allowing people to work from wherever they need to, and we want to help you balance work with your other goals. And we promote flexible work schedules and hate overtime.</p>
                                                    </div>
                                                </div>
                                                <div className="vision-box">
                                                    <img src={Growth} />
                                                    <div className="vision-text">
                                                        <h2>Growth</h2>
                                                        <p>Learn about new technologies and develop soft skills to spur your professional growth. We encourage Links4enggineers to try new projects and constantly build on their experience. You’re constantly learning through sharing and learning sessions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="p-0">
                            <div className="about-offers">
                                <div className="container">
                                    <p className="sec-top-title">JOB OFFERS</p>
                                    <div className="sec-bottom">
                                        <h2 className="sec-title">We Have 2 Open Job Opportunities</h2>
                                        <p>Seeking passionate individuals to join our team. Dynamic work environment, fostering innovation and
                                            <br />
                                            growth. Be part of something amazing. Apply now!</p>
                                    </div>
                                    <div className="oppenings">
                                        <div className="job">
                                            <h2>Senior UI/UX Designer</h2>
                                            <p className="experience">5+ years as a UI/UX Designer</p>
                                            <p className="job-detail">As a Senior UI/UX Designer, you will play a crucial role in crafting intuitive and visually appealing interfaces. Collaborate with cross-functional teams, conduct user research, and drive design innovation.</p>
                                            <div className="apply-now">
                                                <a className="read-more" href="/Career-detail/#jobdetail">Read More <BsFillCaretRightFill /></a>
                                                <button className="btn btn-primary apply-btn"><a href="/Career-detail/#applynow"> Apply Now</a></button>
                                            </div>
                                        </div>
                                        <div className="job">
                                            <h2>IT Project Manager (Web & Mobile Apps)</h2>
                                            <p className="experience">5+ years as a Project Manager</p>
                                            <p className="job-detail">Seeking an experienced IT Project Manager to oversee the successful delivery of web and mobile app projects. Lead cross-functional teams, ensure project milestones are met, and drive quality results. Join us for an exciting opportunity.</p>
                                            <div className="apply-now">
                                                <a className="read-more" href="/Career-detail/#jobdetail">Read More <BsFillCaretRightFill /></a>
                                                <button className="btn btn-primary apply-btn"><a href="/Career-detail/#applynow">Apply Now</a></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="process">
                                        <p className="sec-top-title">THE PROCESS</p>
                                        <div className="sec-bottom">
                                            <h2 className="sec-title">A Powerful Approach
                                                <br /> For Building New Era</h2>
                                            <p>Our interview process is designed to assess your skills, qualifications, and cultural
                                                <br />
                                                fit within our organization. It typically consists of the following stages:</p>
                                        </div>
                                        <div className="qualities">
                                            <div className="quality-inner">
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>01</h2>
                                                            <h4>Initial Screening</h4>
                                                            <p>Review of application and resume.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>02</h2>
                                                            <h4>Technical Assessment</h4>
                                                            <p>Evaluation of skills and proficiency.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>03</h2>
                                                            <h4>In-person/On-site Interview</h4>
                                                            <p>Assessing skills, behavior, and work experience.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>04</h2>
                                                            <h4>Team Collaboration</h4>
                                                            <p>Assessment of teamwork abilities</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>05</h2>
                                                            <h4>Final Interview</h4>
                                                            <p>Meeting with decision-makers</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="quality-text">
                                                            <h2>06</h2>
                                                            <h4>Onboarding</h4>
                                                            <p>Job offer and smooth transition into the organization.</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    )
};

export default Careers;